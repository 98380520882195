@import '@/styles/variables.scss';

.title {
  font-family: 'Inter', sans-serif;
  color: $white;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  letter-spacing: -3px;
  margin: 0;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 78px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 54px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 32px;
  } 
}

.title span {
  padding: 0;
}

.isItalic {
  font-weight: 300;
  // font-style: italic;
   color: $grey;

    @media screen and (max-width: map-get($breakpoints, xxl)) {
      font-size: 78px;
    }
  
    @media screen and (max-width: map-get($breakpoints, lg)) {
      font-size: 54px;
    }
  
    @media screen and (max-width: map-get($breakpoints, sm)) {
      font-size: 32px;
    }
}

.isWhite {
  color: $white;
}

.isDark {
  color: $black;
}