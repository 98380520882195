@import "@/styles/variables.scss";

.pageContainerWrapper {
  padding: 200px 52px 52px 52px;
  background-color: $white;

  min-height: 100vh;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding: 178px 44px 44px 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding: 148px 40px 40px 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 188px 24px 24px 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 120px 16px 16px 16px;
  }
}

.pageContainer {
  display: grid;
  grid-template-columns: 8fr 4fr;

  gap: 120px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    grid-template-columns: 8fr 4fr;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    grid-template-columns: 11fr 1fr;
    gap: 100px
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    grid-template-columns: 100%;
    gap: 100px
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 40px
  }
}

.contentBlock {
  grid-column: 1/2;
}

.contentBlock__title {
  color: $black;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 

  padding-bottom: 50px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 36px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 24px;
    padding-bottom: 20px;
  }
}

.contentBlock__textSection {
  color: $black;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.5%;
}

.boldText {
  font-weight: 800;
}

.link {
  padding-bottom: 1px;
  border-bottom: 1px solid $black;

  cursor: pointer;
}

.copyright {
  color: $black;

  display: flex;
  width: 100%;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.5%;
  justify-content: flex-end;
  
  padding-top: 110px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding-top: 100px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding-top: 70px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding-top: 40px;
    justify-content: flex-start;
  }

}