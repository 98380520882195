@import '@/styles/variables.scss';

.pageSection {
  background-color: $white;
  height: 100lvh;
  width: 100%;

  display: flex;
  gap: 58px;
  flex-direction: column;
  overflow: hidden;

  padding-top: 140px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    gap: 48px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding-top: 118px;
    gap: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    gap: 100px;
    padding-top: 280px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 40px;
    padding-top: 120px;
  }
}

.titleWrapper {
  padding-inline: 63px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding-inline: 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding-inline: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding-inline: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding-inline: 16px;
  }
}

.swiperWrapper {
  width: 100%;

  cursor: none;
}

.swiperItem {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 620px;
  box-sizing: border-box;
  transition: width 0.5s ease;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 530px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 500px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 360px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
  }
}

.swiperItem__title {
  color: $black;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; 
  width: 702px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 22px;
    width: 620px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    font-size: 20px;
    width: 625px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 18px;
    width: 340px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 16px;
    width: 288px;
  }
}

.swiperItem__text {
  opacity: 0;
  color: $dark-grey;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%;
  width: 702px;
  transform: translate3d(0, 50px, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 620px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 625px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 14px;
    width: 440px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
    opacity: 1;
    transform: none;
  }
}

.swiperItem__image, .swiperItem__video {
  width: 620px;
  height: 320px;
  object-fit: cover;

  border-radius: 52px;
  transition: all 0.5s ease;

  animation: preview 10s infinite linear;
  
  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 530px;
    height: 275px;
    border-radius: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 500px;
    height: 250px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 360px;
    height: 159px;
    border-radius: 30px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
    height: 159px;
    border-radius: 20px;
  }
}

.swiperItem__videoWrapper {
  height: 400px;

  display: flex;
  align-items: flex-end;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    height: 345px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    height: 384px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    height: 264px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    height: 159px;
  }
}

@keyframes preview {
  0%   { background: #fff; }
  10%  { background: #E9E8E8; }  
  20%  { background: #F4F4F4; }
  30%  { background: #E9E8E8; }
  40%  { background: #F4F4F4; }
  50%  { background: #E9E8E8; }
  60%  { background: #F4F4F4; }
  70%  { background: #E9E8E8; }
  80%  { background: #F4F4F4; }
  90%  { background: #E9E8E8; }
  100% { background: #ffff; }
}