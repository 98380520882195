@import '@/styles/variables.scss';

.container {
  background-color: $white;
  height: 100lvh;
  display: flex;
  align-items: center;

  justify-content: space-between;
  box-sizing: border-box;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding-inline: 24px;
    flex-direction: column-reverse;
    justify-content: center;    
    gap: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding-inline: 16px;
    gap: 40px;
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;

  gap: 80px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    gap: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    gap: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    gap: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 40px;
  }
}

.contentWrapper__title {
  max-width: 1405px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    max-width: 954px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    max-width: 1000px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    max-width: 720px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    max-width: 280px;
  }
}

.linksList {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.linksList__item {
  color: $black;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 257.5%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 20px;
    line-height: 150%;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 16px;
  }
}

.closeButton {
  position: absolute;
  right: 52px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
   position: relative;
   right: auto;
  }
}

.closeButton svg {
  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 62px;
    height: 62px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 30px;
    height: 30px;
  }
}