.page-swiper {
  height: 100vh;

  .pageSLide {
    width: 100%;
    height: 100vh;
  }

  .swiper-wrapper {
    padding-left: 0;
  }
}

