@import "@/styles/variables.scss";

.menuItem {
  position: relative;
  color: $white;
  text-align: right;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  // line-height: 257.5%;
  transition: color 0.5s ease;
  display: inline-block;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 14px;
  }
}

.menuItem__line {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 1px;
  background: $white;
  left: 0;
}

.menuItem__innerWrapper {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.menuItem__soon::before {
  display: block;
  position: absolute;
  content: 'С  К  О  Р  О';
  color: #29EAF6;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 257.5%;

  top: 8px;
  right: 115px;

  width: max-content;
}