@import '@/styles/variables.scss';

.SliderSlide__swiperWrapper {
  .swiper-wrapper {
  align-items:baseline;
}
}

.sliderSlide__slide {
  // width: max-content;
  height: auto;

  transition: all 0.5s ease;
  width: 620px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 530px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 500px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 360px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
  }
}


.loopSlide {
  width: 702px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 620px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 625px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 440px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
  }
}

.loopVideo {
  width: 702px;
  height: 340px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 620px;
    height: 320px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 625px;
    height: 300px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 440px;
    height: 220px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 288px;
    height: 159px;
  }
}

.showText {
  opacity: 1;
  transform: none;
}

