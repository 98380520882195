@import '@/styles/variables.scss';

.header__wrapper {
  position: fixed;
  z-index: 100;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr;

  padding-inline: 52px;
  padding-top: 30px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding-inline: 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding-inline: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding-inline: 24px;
    padding-top: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding-inline: 16px;
    padding-top: 16px;
  }
}

.logoIconLink {
  grid-column: 6/7;
  justify-self: center;
}

.logoIcon {
  color: white;
  transition: color .5s ease;
  display: block;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 48px;
    height: 48px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 30px;
    height: 30px;
  }
}

.logoIconBorder {
  border: 2px solid white;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.5s ease;
  overflow: hidden;
}

.logoIconTextWrp {
  display: inline-block;
  overflow: hidden;
}

.logoIconText {
  display: inline-block;
  padding-right: 25px;
  color: white;
  transition: color 0.5s ease;
}

.burgerMenuWrapper {
  grid-column: 9/12;
  justify-self: flex-end;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  padding-top: 12px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding-top: 8px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-column: 10/12;
    padding-top: 3px;
  }
}

.burgerMenu {
  $menu: &;
  position: relative;
  width: 40px;
  height: 40px;

  &__title {
    color: #fff;
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    transition: color 0.5s ease;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__item {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: $white;
    transition: .5s ease;

    &:first-child {
      top: 14px;
    }

    &:last-child {
      bottom: 14px;
    }
  }

  &__open {
    & #{$menu}__item {
      width: 32px;

      &:first-child {
        transform: rotate(45deg);
        left: 5px;
        top: 20px;
      }
      &:last-child {
        transform: rotate(-45deg);
        left: 4px;
        bottom: 17px;
      }
    }
  }
}

.pageTitle {
  color: $white;
  text-align: right;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 257%;
  transition: opacity 0.3s ease, color .5s ease;

  width: max-content;

  &_hidden {
    opacity: 0;
    transition-duration: 0.1s;
  }

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 16px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 14px;
    line-height: 14px;

    padding-top: 10px;

    max-width: 180px;
  }
}

.BurgerMenu {
  display: block;
  position: absolute;
  z-index: 5;

  right: 52px;
  top: 70%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    right: 44px;
    top: 65%;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    right: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    right: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    right: 16px;
  }
}