@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}

.scrollDown__scroll {
  animation: scroll ease 1.5s infinite;
}