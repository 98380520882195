@import '@/styles/variables.scss';

.pageSection {
  height: 100lvh;
  display: flex;
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  align-items: flex-end;

  position: absolute;
  bottom: 0;
  left: 0;

  padding: 0 48px 48px 72px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    justify-content: flex-end;
    padding: 0 40px 88px 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 0 16px 0 16px;
    bottom: 25lvh;
  }

}

.buttonContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    gap: 40px;
    align-items: center;
    justify-content: center;
  }
}

.circleImageContainer {
  padding: 74px;
  position: relative;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 0;
  }
}
 
.button {
  display: block;
  width: auto;
}

.buttonCircle {
  display: block;
}

.buttonCross {
  position: absolute;
  left: 0;
  top: 0;
}

.buttonWrapperOuter {
  display: block;
  position: relative;
}

.buttonImage {
  display: block;
  box-sizing: border-box;
  object-fit: cover;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 88px;
    height: 88px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    animation: 4s showButtonImage ease;
  }
}

.quoteText {
  color: $white;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;

  max-width: 500px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    display: none;
  }
}

.circleText {
  position: absolute;
  top: 3%;
  left: 2%;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    top: -30%;
    left: -30%;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    top: -26%;
    left: -26%;
  }
}

.backgroundVideoWrapper {
  width: 100%;
  overflow: hidden;
}

.backgroundVideo, .backgroundImage {
  object-fit: cover;    
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;


  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: none;
  }
}

.backgroundVideo__mobile, .backgroundImage__mobile {
  display: none;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-inline: 50px;

  position: absolute;
  z-index: 3;
  top: 50%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding-inline: 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding-inline: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding-inline: 10px;
  }
}

.offerFormMessage {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 2;
  max-width: 405px;
  background-color: #000;
  color: #C4C4C4;
  padding: 24px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  opacity: 0;
  animation: fadeIn 0.6s linear;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  @media screen and (max-width: 768px) {
    bottom: 24px;
    right: 24px;
    max-width: 374px;
    padding: 20px;
  }

  @media screen and (max-width: 540px) {
    bottom: 80px;
    right: 16px;
    left: 16px;
    max-width: none;
    padding: 14px;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #fff;
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;

    @media screen and (max-width: 540px) {
      font-size: 16px;
      line-height: 27px;
    }

    svg {
      cursor: pointer;

      &:hover {
        path {
          fill: #fff;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    margin-top: 16px;
    gap: 8px;
  }

  &__button {
    width: 100%;
    padding: 14px 24px;
    border-radius: 36px;
    border: 1px solid #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    transition: 0.35s;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showButtonImage {
  from { opacity: 0; }
  to { opacity: 1; }
}