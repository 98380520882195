@import '@/styles/variables.scss';

.pageSection {
  height: 100lvh;
  display: flex;
  position: relative;
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  align-items: flex-end;

  position: absolute;
  bottom: 0;
  left: 0;

  padding: 0 48px 48px 72px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 0 40px 24px 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 0 16px 16px 16px;
  }

}

.buttonContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.circleImageContainer {
  padding: 74px;
  position: relative;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding: 55px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 50px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 40px;
  }
}

.buttonImage {
  display: block;
  box-sizing: border-box;
  object-fit: cover;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 88px;
    height: 88px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 79px;
    height: 79px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 63px;
    height: 63px;
  }
}

.quoteText {
  color: $white;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;

  max-width: 500px;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    display: none;
  }
}

.circleText {
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 180px;
    height: 180px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 144px;
    height: 144px;
  }
}

.backgroundVideoWrapper {
  width: 100%;
}

.backgroundVideo {
  object-fit: cover;    
  height: 100%;
  width: 100%;
}

.backgroundImage {
  object-fit: cover;    
  height: 100%;
  width: 100%;
}

.textWrapper {
  position: absolute;
  top: 185px;
  left: 64px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 46px;
  justify-items: baseline;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    row-gap: 26px;
    top: 178px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    row-gap: 26px;
    top: 148px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    left: 16px;
    row-gap: 18px;
    top: 120px;
    max-width: 288px;
  }
}

.textWrapper div {
  grid-column: 1/12;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    grid-column: 1/9;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-column: auto;
    max-width: 288px;
  }
}

.textWrapper button {
  color: $white;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 148.5%; 

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 16px;
  }
}

.moreButton {
  padding: 0;
  grid-row: 2/3;

  padding-inline: 6px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding-inline: 2px;
  }
}

.showMoreModal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
}