@import "@/styles/variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', Helvetica, sans-serif;
  background-color: $black;

  height: 100%;
}

h1, H2, h3, h4 {
  margin: 0;
}

p {
  padding: 0;
  margin: 0;

  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 171.5%;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.title {
  font-family: 'Inter', sans-serif;
  color: $white;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  letter-spacing: -3px;
  margin: 0;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 78px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 54px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 32px;
  } 
}

.italicTitle {
  font-weight: 300;
  // font-style: italic;
  color: $white;

    @media screen and (max-width: map-get($breakpoints, xxl)) {
      font-size: 78px;
    }
  
    @media screen and (max-width: map-get($breakpoints, lg)) {
      font-size: 54px;
    }
  
    @media screen and (max-width: map-get($breakpoints, sm)) {
      font-size: 32px;
    }
}

.greyTitle {
  color: $grey;
}

.darkTitle {
  color: $black;
}

//светлая тема с темным текстом

.theme--light .menuItem {
  color: $black;
}

.theme--light .burgerMenu__title {
  color: $black;
}

.theme--light .burgerMenu__item {
  background-color: $black;
}

.theme--light .menuItem__line {
  background: $black;
}

.theme--light .menuPoint {
  background-color: $black;
}

.theme--light .logoIcon {
  color: $black;
}

.theme--light .pageTitle {
  color: $black;
}

.theme--light .logoIconBorder {
  border-color: $black;
}

.theme--light .logoIconText {
  color: $black;
}

//светлая тема с темным текстом

.theme--dark .menuItem {
  color: $white;
}

.theme--dark .menuItem__line {
  background: $white;
}

.theme--dark .menuPoint {
  background-color: $white;
}

.theme--dark .logoIcon {
  color: $white;
}

.theme--dark .pageTitle {
  color: $white;
}

.theme--dark .logoIconBorder {
  border-color: $white;
}

.theme--dark .logoIconText {
  color: $white;
}