@import '@/styles/variables.scss';

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.pointsLayer,
.sliderLayer,
.textLayer {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.pointsLayer {
  z-index: 1;
}

.sliderLayer {
  z-index: 2;
}

.textLayer {
  z-index: 1;

  &__top {
    position: absolute;
    right: 60px;
    top: 100px;
    left: 60px;

    @media screen and (max-width: map-get($breakpoints, xxl)) {
      right: 44px;
      left: 44px;
    }

    @media screen and (max-width: map-get($breakpoints, xl)) {
      right: 40px;
      left: 40px;
    }
  }

  &__bottom {
    position: absolute;
    right: 60px;
    bottom: 150px;
    left: 60px;

    @media screen and (max-width: map-get($breakpoints, xxl)) {
      right: 44px;
      left: 44px;
    }

    @media screen and (max-width: map-get($breakpoints, xl)) {
      right: 40px;
      left: 40px;
    }
  }
}

.scrollDown {
  position: absolute;
  right: 40px;
  bottom: 50px;
}

.title {
  color: $white;
  line-height: 100%;
}

.description {
  color: $white;
  font-size: 56px;

  font-style: normal;
  font-weight: 400;
  line-height: 106%;

  max-width: 1030px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 48px;
    max-width: 950px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    font-size: 44px;
    max-width: 1000px;
  }
}

.textCarousel {
  overflow: hidden;
  position: relative;
}

.textCarouselContainer {
  position: absolute;
  left: 0;
  top: 0;
}

.paginationContainer {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}

.paginationItem {
  color: #FFF;
  font-size: 17px;
  position: relative;
  overflow: hidden;

  &_active {
    .paginationItemInner {
      &::before {
        transform-origin: 100% 0;
        transform: scaleX(0);
      }
    }
  }
}

.paginationItemInner {
  position: relative;
  padding-bottom: 3px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: #FFF;
    transition: transform 0.5s ease;
    transform-origin: 0 0;
  }
}

.pointSvg {
  cursor: pointer;

  &_hidden {
    display: none;
  }
}

.closeBtn {
  position: absolute;
  z-index: 3;
  right: 50px;
  bottom: 50%;
  cursor: pointer;

  &_hidden {
    display: none;
  }
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: white;
  background: rgba($color: #000, $alpha: 0.5);
}