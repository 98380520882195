@import '@/styles/variables.scss';

.container {
  display: none;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    display: flex;
    flex-direction: column;
  }
}

.previewSlide {
  height: 100lvh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.previewSlide__title {
  z-index: 2;
  padding: 150px 24px 0 24px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 120px 16px 0 16px;
  }
}

.backgroundVideo {
  position: absolute;
  z-index: 1;
  object-fit: cover;    
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}