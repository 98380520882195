.pullCursor {
  position: fixed;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #E3E3E3;
  left: -100px;
  top: -100px;
  z-index: 1000;
  pointer-events: none;
  display: none;
  color: #E3E3E3;

  &_visible {
    display: block;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
