@import "@/styles/variables.scss";

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: -20px;

  &_hidden {
    display: none;
  }
}

.itemWrapper {
  overflow: hidden;
  padding: 0 20px;
  margin-top: 15px;

  &:first-child {
    margin-top: 10px;
  }
}
