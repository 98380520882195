$breakpoints: (
    sm: 767px,
    lg: 1279px,
    xl: 1359px,
    xxl: 1800px
);


$white: #FFFFFF;
$black: #000;
$grey: #B3B3B3;
$dark-grey: #605E5E;
$light-grey: #c4c4c4;


$path-to-font: '../assets/fonts/';
