@import "@/styles/variables.scss";

.pageSection__contacts {
  height: 100lvh;
  background-color: $white;
  
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  flex-direction: column;
  gap: 200px;
  align-items: flex-start;
  padding: 161px 41px 55px 63px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding: 178px 44px 44px 44px;
    gap: 150px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding: 148px 40px 40px 40px;
    gap: 120px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 148px 24px 24px 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    justify-content: space-between;
    gap: 40px;
    padding: 120px 16px 100px 16px;
    }
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;


  @media screen and (max-width: map-get($breakpoints, lg)) {
    justify-content: flex-end;
  }
}

.footerLink {
  align-self: flex-end;
font-size: 17px;
font-weight: 400;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 16px;
  }
}

.contactsSection {
  display: grid;
  grid-template-columns: 3fr 6fr 2fr;
  width: 100%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    gap: 80px 10px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    grid-template-columns: 3fr 7fr;
    gap: 60px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

.contactsSection__itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    gap: 50px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    display: contents;
  }
}

.contactsSection__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactsSection__innerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contactsSection__innerDoubleContainer {
  flex-direction: row;
  gap: 80px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    flex-direction: column;
    gap: 20px;
  }
}

.lightText {
  color: $grey;
}