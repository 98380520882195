@import '@/styles/variables.scss';

.pageSection {
  height: 100vh;
  background-color: $white;

  display: flex;
  flex-direction: column;
  gap: 120px;

  justify-content: center;
  align-items:center;
  overflow: hidden;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    gap: 100px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    gap: 80px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 40px;
  }
}

.imagesContainer {
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.title {
  color: $dark-grey;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; 

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 20px;
    padding-inline: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 16px;
    padding-inline: 16px;
  }
}

.swiperSlide {
  width: auto;
}