.loader {
  width: 64px;
  height: 64px;
  opacity: 1;
  position: relative;
}

.loaderParts {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2087fd transparent transparent transparent;
  opacity: 1;

  &:nth-child(1) {
    border-color: rgba(255, 255, 255, 0.9);
  }
  &:nth-child(3) {
    animation-delay: 0.35s;
  }
  &:nth-child(4) {
    animation-delay: 0.25s;
  }
  &:nth-child(5) {
    animation-delay: 0.15s;
  }
}

.loaderPercentage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}