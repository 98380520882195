@import '@/styles/variables.scss';

.pageSection {
  height: 100lvh;
  width: 100%;
  background-color: $black;
  position: relative;

  padding: 160px 52px 10px 52px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    padding: 178px 44px 10px 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    padding: 148px 40px 10px 40px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    padding: 148px 24px 10px 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    padding: 120px 16px 20px 16px;
    height: 100%;
  }
}

.inputsWrapper {
  display: grid;
  grid-template-columns: 4fr 4fr;

  gap: 50px 28px;

  max-width: 1240px;
  padding-top: 102px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    max-width: 1080px;
    padding-top: 80px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    max-width: 1020px;
    gap: 42px 20px;
    padding-top: 70px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    max-width: 540px;
    gap: 42px;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    max-width: 100%;
    gap: 34px;
    padding-top: 40px;
  }
}

.submitButton {
  color: $white;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; 

  padding: 26px 44px;
  border-radius: 36px;
  border: 1px solid $light-grey;

  margin-top: 40px;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    margin-top: 30px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    margin-top: 62px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-top: 44px;
    padding: 20px 106px;
    width: 100%;
  }
}

.agreementContainer {
  color: $grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;

  max-width: 605px;

  margin-top: 30px;
}

.agreementContainer a {
  color: $white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  text-decoration-line: underline;
}

.closeButton {
  position: absolute;
  right: 52px;
  top: 50%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    right: 44px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    right: 44px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    top: 15%;
    right: 24px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    top: 15%;
    right: 16px;
  }
}

.closeButton svg {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 30px;
    height: 30px;
  }
}

.successMessageContainer {
  width: 362px;
  height: 179px;

  border-radius: 6px;
  background-color: $white;
  padding: 30px;
  gap: 12px;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 260px;
    height: 131px;
    background-color: $white;
    padding: 20px;
    gap: 12px;
  }
}

.successMessageContainer__textContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.successMessageContainer__title {
  color: $black;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 14px;
  }
}

.successMessageContainer__description {
  color: $dark-grey;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%; 

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 14px;
  }
}

.showMoreModal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
}