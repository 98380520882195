@import '@/styles/variables.scss';

.icon {
  animation: 30s linear 0s normal none infinite running decor;
  -webkit-animation: 30s linear 0s normal none infinite running decor;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 264px;
    height: 264px;
  }

  @media screen and (max-width: map-get($breakpoints, xl)) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 140px;
    height: 140px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 136px;
    height: 136px;
  }
}

.banner {
  display: block;
  position: absolute;
  top: 20%;
  left: 30%;
  pointer-events: none;
  z-index: 5;

  max-width: max-content;

  object-fit: contain;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 330px;
    height: 80px;

    top: -150%;
    left: -200%;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 75vw;
    height: auto;

    top: -100px;
    left: -40%;
  } 
}

@keyframes decor {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
  @-webkit-keyframes decor {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}