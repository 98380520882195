@import '@/styles/variables.scss';

.wrapper {
  width: 100%;
}

.container {
  position: relative;
  height: 64px;
  background-color: transparent;

  border: 1px solid $grey;
  border-radius: 6px;

  &:focus-within {
  border: 1px solid $grey;
  }

  &:invalid {
    border: 1px solid $grey;

    @media screen and (max-width: map-get($breakpoints, sm)) {
      height: 58px;
    }
  }
}

.label {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 100%;
  pointer-events: none;

  font-size: 17px;
  line-height: 20px;
  color: $grey;
  font-weight: 400;

  transform-origin: 0 0;
    transform: translate3d(0,0,0);
    transition: all .2s ease;
    pointer-events: none;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 16px;
    line-height: 18px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    line-height: 16px;
  }
}

.input {
  -webkit-appearance: none;
  appearance: none;

  font-family: inherit;
  background-color: transparent;
  display: block;

  height: 100%;
  width: 100%;
  outline: none;
  border: none;

  color: $white;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;

  padding-block: 24px 10px;

  padding-inline: 20px;

  transition: all .15s ease;

  @media screen and (max-width: map-get($breakpoints, lg)) {
    font-size: 16px;
    line-height: 18px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 16px;
    line-height: 16px;
  }

  &::placeholder {
   color: transparent;
  }

  &:not(:placeholder-shown)
      + .label {
        transform: translate3d(0,-12px,0) scale(.75);
      }

      &:focus + .label {
        transform: translate3d(0,-12px,0) scale(.75);
      }
      
}

.input:disabled {
  background-color: $grey;
  color: $grey;
}

.helperText {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $black;
  text-align: left;

  padding-top: 5px;

  &:invalid {
    color: #fff;
  }

  :disabled {
    color: $grey;
}
}



