@font-face {
  font-family: 'Inter';
  src: url('../general/fonts/Inter-Regular.ttf');
  src: local('Inter Regular'), local('Inter-Regular'),
      url('../general/fonts/Inter-Regular.woff') format('woff'),
      url('../general/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../general/fonts/Inter-Medium.ttf');
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../general/fonts/Inter-Medium.woff') format('woff'),
  url('../general/fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../general/fonts/Inter-Bold.woff');
  src: local('Inter-Bold'),
      url('../general/fonts/Inter-Bold.ttf') format('ttf');
  font-weight: 800;
}

