@import '@/styles/variables.scss';

.video, .image {
  object-fit: cover;
  border-radius: 50%;

  animation: preview 10s infinite linear;
  
  width: 394px;
  height: 394px;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    width: 300px;
    height: 300px;
  }

  @media screen and (max-width: map-get($breakpoints, lg)) {
    width: 240px;
    height: 240px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 190px;
    height: 190px;
  }
}

@keyframes preview {
  0%   { background: #fff; }
  10%  { background: #E9E8E8; }  
  20%  { background: #F4F4F4; }
  30%  { background: #E9E8E8; }
  40%  { background: #F4F4F4; }
  50%  { background: #E9E8E8; }
  60%  { background: #F4F4F4; }
  70%  { background: #E9E8E8; }
  80%  { background: #F4F4F4; }
  90%  { background: #E9E8E8; }
  100% { background: #ffff; }
}