@import '@/styles/variables.scss';

.text {
  color: $grey;
  font-size: 17px;
  font-style: italic;
  font-weight: 300;
  line-height: 171.5%;

  @media screen and (max-width: map-get($breakpoints, xxl)) {
    font-size: 16px;
  }
}